import React from "react"
import Layout from "../components/layout"
import {FormattedMessage, injectIntl, useIntl} from "gatsby-plugin-react-intl"
import Seo from "../components/seo"

const NotFoundPage = () => {
    const intl = useIntl()
    const lang = intl.locale
    return (
        <Layout>
            <Seo lang={lang} title={intl.formatMessage({id: "title"})}/>
            <div style={{minHeight: `95vh`}}>
                <h1 style={{textAlign: `center`, paddingTop: `300px`}}><FormattedMessage id="notfound.header"/></h1>
                <p style={{textAlign: `center`}}><FormattedMessage id="notfound.description"/></p>
            </div>
        </Layout>
    )
}

export default injectIntl(NotFoundPage)
